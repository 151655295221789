import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "gatsby";

import Container from "./Container";
import "./NewsletterRow.css";

const NewsletterRow = (props) => {
  const data = useStaticQuery(graphql`
    query {
      bike: file(relativePath: { eq: "renderB-cropped.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, layout: FULL_WIDTH)
        }
      }
      logotransparent: file(relativePath: { eq: "logo_transparent-02.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: FULL_WIDTH)
        }
      }
    }
  `);
  const { newsletterRef } = props;

  const [email, setEmail] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { hideHeading } = props;

  return (
    <Container containerStyle={{ backgroundColor: "white", paddingBottom: 0 }}>
      {!hideHeading ? (
        <h2 ref={newsletterRef}>Miejska e-mobilność nadchodzi w 2022</h2>
      ) : (
        <p style={{ marginTop: "5rem" }}></p>
      )}
      <p>
        Chcesz wiedzieć co u Hazaya? Zapisz się do naszego <b>newslettera</b>.
      </p>
      <div style={{ display: "flex", flexWrap: "wrap", zIndex: "5" }}>
        <form
          action="https://app.getresponse.com/add_subscriber.html"
          accept-charset="utf-8"
          method="post"
          style={{ zIndex: 5 }}
        >
          <input
            className="newsletter-signup-input"
            placeholder="Twój adres email"
            name="email"
            type="text"
            required
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            onChange={(event) => setEmail(event.target.value)}
          ></input>
          <input type="hidden" name="campaign_token" value="zFx50" />
          <input type="hidden" name="start_day" value="0" />
          <input
            type="hidden"
            name="thankyou_url"
            value="https://hazaybikes.com/subscription-confirmation/"
          />
          <input
            type="submit"
            className="newsletter-row-signup-button"
            value="Zapisz się!"
            onClick={() => {
              const valid = /[^@\s]+@[^@\s]+\.[^@\s]+/.test(email);
              email && valid && setShowConfirmation(true);
            }}
          />
        </form>
      </div>
      <small style={{ zIndex: "30" }}>
        * Podając adres e-mail akceptuję{" "}
        <Link style={{ color: "black" }} to="/regulamin">
          Polityke Prywatności
        </Link>
        .
      </small>
      {showConfirmation && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <p style={{ margin: 0, marginLeft: "0.5rem" }}>Subscribed!</p>
        </div>
      )}
      <div className="newsletter-row-images">
        <GatsbyImage
          image={getImage(data.logotransparent)}
          alt={data.logotransparent.name}
          className="newsletter-row-logo-transparent"
        />
        <GatsbyImage
          image={getImage(data.bike)}
          alt={data.bike.name}
          style={{ marginTop: "4rem" }}
        />
      </div>
    </Container>
  );
};

export default NewsletterRow;
