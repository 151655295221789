import React from "react";

import { rhythm } from "../../utils/typography";

const Container = (props) => {
  const { children, contentStyle, containerStyle } = props;
  return (
    <div
      style={{
        ...{
          padding: `${rhythm(2 / 4)} 0 ${rhythm(3 / 4)}`,
        },
        ...containerStyle,
      }}
    >
      <div className="landing-page-limiter" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default Container;
